import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import tw from 'twin.macro'

import Seo from 'gatsby-plugin-wpgraphql-seo'
import CallToAction from '../../components/CallToAction'
import Container from '../../components/Container'
import CooksGuarantee from '../../components/CooksGuarantee'
import FAQSection from '../../components/FAQSection'
import { H3 } from '../../components/Headings'
import HeroSection from '../../components/HeroSection'
import Layout from '../../components/Layout'
import Testimonials from '../../components/Testimonials'
import { formatYoastSeo } from '../../helpers'

const PageTemplate = ({ data, location, preview }) => {
  const page = preview ? preview : data.allWpPage.nodes[0]

  const {
    heroSection,
    featuredGraphicSection,
    cooksGuarentee,
    faqSection,
    callToAction,
  } = page

  return (
    <Layout location={location}>
      {!preview && (
        <Seo
          title={`${page.title} - ${JSON.parse(
            data.wp.seo.contentTypes.post.schema.raw
          )['@graph'][0]?.name.replace('&#039;', "'")}`}
          postSchema={formatYoastSeo(data.wp.seo.contentTypes.post.schema).raw}
        />
      )}

      <HeroSection {...heroSection} />

      <section
        css={[
          tw`mb-16 bg-yellow-600 relative w-full pb-10 h-full overflow-x-hidden md:(pt-10 h-auto bg-white)`,
        ]}
      >
        {/* Make the top part of the section white; */}
        <div tw="flex absolute w-full top-0 left-0 h-24 z-0 bg-white lg:(flex absolute w-full top-0 left-0 h-24 z-0 bg-white)" />
        <div tw="hidden lg:(flex absolute w-full bottom-0 left-0 h-24 z-0 bg-white)" />

        <Container tw="flex flex-col-reverse lg:(grid gap-8 grid-cols-2) z-10 px-10">
          <div tw="z-10 flex text-center justify-center content-center flex-col md:(py-20 text-left)">
            {featuredGraphicSection?.headline && (
              <H3 tw="text-center font-normal md:(text-left font-bold)">
                {featuredGraphicSection.headline}
              </H3>
            )}
            {featuredGraphicSection?.subHeadline && (
              <p tw="text-center md:(text-left)">
                {featuredGraphicSection.subHeadline}
              </p>
            )}
            {featuredGraphicSection?.content && (
              <div
                tw="md:(text-gray-700)"
                dangerouslySetInnerHTML={{
                  __html: featuredGraphicSection.content,
                }}
              />
            )}
          </div>
          <div tw="z-10 flex justify-center content-center flex-col md:(py-10)">
            {featuredGraphicSection?.image?.localFile ? (
              <Img
                fluid={
                  featuredGraphicSection.image?.localFile?.childImageSharp
                    ?.fluid
                }
              />
            ) : (
              <img
                src={featuredGraphicSection.image?.sourceUrl}
                tw="w-full -my-6"
                loading="lazy"
              />
            )}
          </div>
        </Container>
      </section>

      <CooksGuarantee {...cooksGuarentee} />
      <div tw="mt-16">
        <FAQSection {...faqSection} />
      </div>
      <Container tw="py-12">
        <Testimonials.Carousel />
      </Container>
      <CallToAction {...callToAction} />
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    wp {
      ...WpSeoSchema
    }
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        ...HeroSection
        ...FeaturedGraphicSection
        ...CooksGuarentee
        ...FaqSection
        ...CallToAction
        title
        content
      }
    }
  }
`
