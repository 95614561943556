import React from 'react'
import tw from 'twin.macro'
import { H2 } from './Headings'
import CtaButton from './CTAButton'
import { handleTextColor } from '../styles/helpers'

const CallToAction = ({
  headline,
  paragraph,
  ctaButton,
  column,
  appearance,
}) => {
  if ((headline || paragraph) && ctaButton)
    return (
      <section
        css={[column ? tw`my-4 md:(my-12)` : tw`py-12 md:(py-16)`]}
        aria-label="Get Your Free Estimate"
      >
        <div css={[tw`mx-auto`, !column && tw`container px-8`]}>
          {/* Footer CTA */}
          <div tw="text-center content-center">
            {headline && (
              <H2 css={[tw`text-2xl`, handleTextColor(appearance?.textColor)]}>
                {headline}
              </H2>
            )}

            <div
              css={[
                tw`text-base leading-relaxed text-gray-700`,
                !column && tw`px-4 lg:(px-10 mx-10) xl:(px-24 mx-24)`,
                handleTextColor(appearance?.textColor),
              ]}
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
            {/* <p tw="text-base px-4 leading-relaxed text-gray-700 lg:(px-10 mx-10) xl:(px-24 mx-24)">
            {paragraph}
          </p> */}

            <CtaButton {...ctaButton} />
          </div>
        </div>
      </section>
    )

  return null
}

CallToAction.defaultProps = { column: false }

export default CallToAction
